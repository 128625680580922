export default {
  name: "LandingPage",
  created() {
    window.getRicToDetails = this.getRicToDetails;
    this.$nextTick(function () {
      // localStorage.clear()
      /*显示table list*/
      this.showTable();
      /*获取ric list*/
      this.GetUnderlyingList()
      this.getRiclist();
      /*打印table*/
      this.printTable();
      //关联打印table
      this.correlationPrintTbale();
      //combobox
      this.$js.jqcombobox("#matrix-combobox0");
      for (var i = 1; i <= 8; i++) {
        this.$js.jqcombobox("#matrix-combobox" + i);
      }
    });
  },
  mounted() {
    this.$nextTick(function () { });
  },
  data() {
    return {
      dsply_nmll: [],
      mouse: false,
      conmouse: true
    };
  },
  methods: {
    // 重整 bid & ask
    info: function () {
      let timer1;
      this.getRiclist();
      $("#info").attr("disabled", true);
      clearTimeout(timer1);
      //设置一次性定时器
      timer1 = setTimeout(function () {
        $("#info").attr("disabled", false);
      }, 5000);
      this.handleMouse(false);
    },
    //  Refresh 按钮提示
    handleMouse: function (flag) {
      this.mouse = flag;
    },
    showTable: function () {
      /*显示,关闭table list*/
      for (var i = 1; i <= 8; i++) {
        //利用闭包处理循环绑定 -- 解决变量污染问题
        (function (index) {
          $("#bn" + index + " span").addClass("bi-caret-down-fill");
          $("#datalist" + index).addClass("d-none");
          $("#bn" + index).on("click", function () {
            $("#datalist" + index).toggleClass("d-none");
            $("#bn" + index + " span").toggleClass("bi-caret-up-fill");
            $("#bn" + index + " span").toggleClass("bi-caret-down-fill");
          });
        })(i);
      }
    },
    // 获取 underlying ric
    GetUnderlyingList: function () {
      $.ajax({
        type: 'GET',
        url: this.$js.webservice + 'GetUnderlyingList',
        data: { token: 'webkey', issuers: 'RHB' },
        dataType: 'json',
        success: (data) => {
          if (data.status === 'SUCCESS') {
            $("#matrix-combobox0")
              .find("option:eq(0)")
              .nextAll()
              .remove();
            console.log(data);
            $.each(data.underlying_name, (index, comment) => {
              $("#matrix-combobox0").append(
                "<option value=" +
                comment["underlying_ric"] +
                ">" +
                comment["underlying_name"] +
                " (" +
                comment["underlying_ticker"] +
                ")" +
                "</option>"
              );
            });
            this.ricChange0();
          }
        },
        error: function (error) {
          console.log(error)
        }
      })
    },
    GetRICListByUnderlying: function (underlying) {
      this.conmouse = false
      $.ajax({
        type: "GET",
        url: this.$js.webservice + "GetRICListByUnderlying",
        data: { token: "webkey", issuers: "RHB", underlying: underlying },
        dataType: "json",
        success: data => {
          if (data.status === "SUCCESS") {
            this.dsply_nmll = [];
            localStorage.removeItem("priceMatrix")
            $.each(data.ric, (i, c) => {
              if (i + 1 <= 8) {
                this.getRicdatas(i + 1, c.ric, false)
              }
            })
            this.$router.go(0);
          }
        },
        error: function (error) {
          console.log(error);
        }
      });
    },
    getRiclist: function () {
      /*获取ric list*/
      let _this = this;
      $.ajax({
        type: "GET",
        url: this.$js.webservice + "GetRICList",
        data: { token: "webkey" },
        dataType: "json",
        success: data => {
          if (data.status === "SUCCESS") {
            for (var i = 1; i <= 8; i++) {
              $("#matrix-combobox" + i)
                .find("option:eq(0)")
                .nextAll()
                .remove();
              $.each(data.ric, (index, comment) => {
                if (this.$js.isRHB(comment.issuer_name)) {
                  $("#matrix-combobox" + i).append(
                    "<option value=" +
                    comment["ric"] +
                    ">" +
                    comment["dsply_nmll"] +
                    "</option>"
                  );
                }
              });
              _this.ricChange(i);
              // console.log($(".ui-widget_" + i).find('input').val());
              _this.$js.clickEliminate(".ui-widget_" + i);
            }
            /*判断页面默认值*/
            if (localStorage.getItem("priceMatrix") !== null) {
              var newArr = new Array(6).fill("");
              var arr = localStorage.getItem("priceMatrix").split(",");
              arr.forEach(function (it, index) {
                if (it !== "") {
                  /*判断哪一个panel有存储*/
                  for (var i = 0; i < data.ric.length; i++) {
                    if (data.ric[i].ric.indexOf(it) >= 0) {
                      /*判断存储的ric是否存在*/
                      $("#matrix-combobox" + (index + 1)).val("111");
                      _this.dsply_nmll = [];
                      _this.getRicdatas(index + 1, it, true);
                      newArr[index] = it;
                    }
                  }
                }
              });
              localStorage.setItem("priceMatrix", newArr);
            }
          }
        },
        error: function (error) {
          console.log(error);
        }
      });
    },
    ricChange0: function () {
      let _this = this;
      $("#matrix-combobox0").combobox({
        select: function (event, ui) {
          _this.GetRICListByUnderlying(this.value)
          $("#matrix-combobox0")
            .parent()
            .find("input")
            .blur();
        }
      });
    },
    ricChange: function (i) {
      let _this = this;
      $("#matrix-combobox" + i).combobox({
        select: function (event, ui) {
          _this.getRicdatas(i, this.value, false); /*获取当前ric数据*/
          $("#matrix-combobox" + i)
            .parent()
            .find("input")
            .blur();
        }
      });
    },

    getRicdatas: function (i, ric, isInit) {
      /*获取当前ric数据*/
      let _this = this;
      /*同时打开panel*/
      $("#bn" + i + " span").removeClass("bi-caret-down-fill");
      $("#bn" + i + " span").addClass("bi-caret-up-fill");
      $("#datalist" + i).removeClass("d-none");
      $.ajax({
        type: "GET",
        url: this.$js.webservice + "GetPriceMatrixData",
        data: { token: "webkey", ric: ric },
        dataType: "json",
        success: data => {
          if (data.status === "SUCCESS") {
            var ric_data = data.ric_data;
            var live_matrix = data.livematrix;
            _this.dsply_nmll[i - 1] = ric_data["dsply_nmll"];
            $(".ui-widget_" + i)
              .find("input")
              .val(ric_data["dsply_nmll"]);
            if (Number(data.soldout) !== 0) {
              $("#datalist" + i)
                .find(".soldout")
                .css({
                  opacity: "1"
                });
            } else {
              $("#datalist" + i)
                .find(".soldout")
                .css({
                  opacity: "0"
                });
            }
            $(".tableHead" + i + " thead tr:eq(0) td:last").text(
              ric_data["dsply_nmll"]
            );
            $(".tableHead" + i + " thead tr:eq(1) td:last").text(
              ric_data["exercise_price"] !== "N/A"
                ? this.$js.curreryRetrun(ric_data.underlying_curr) +
                " " +
                this.$js.formatNumber(ric_data["exercise_price"], 0, 0)
                : "N/A"
            );
            $(".tableHead" + i + " thead tr:eq(2) td:last").text(
              _this.$js.formatNumber(ric_data["conv_ratio"], 4, 0)
            );
            $(".tableHead" + i + " thead tr:eq(3) td:last").text(
              ric_data["effective_gearing"] !== "N/A"
                ? _this.$js.formatNumber(ric_data["effective_gearing"], 1, 0) +
                " x"
                : "N/A"
            );
            $(".tableHead" + i + " thead tr:eq(4) td:last").text(
              ric_data["sensitivity"] !== "N/A"
                ? _this.$js.formatNumber(ric_data["sensitivity"], 1, 0) +
                " ticks"
                : "N/A"
            );
            
          
            ric_data["TimeToLastTradingDateWithoutWeekendPH"] <= 3 ? $(".tableHead" + i + " thead tr:eq(5) td:last").addClass('red'):' '
            $(".tableHead" + i + " thead tr:eq(5) td:last").text(
              ric_data["TimeToLastTradingDateWithoutWeekendPH"] + " days"
            );
            ric_data["TimeToLastTradingDateWithoutWeekendPH"] <= 3 ? $(".tableHead" + i + " thead tr:eq(5) td:last").append('<span class="bi bi-exclamation-circle-fill" aria-hidden="true"><i>'+_this.$t("m.expiryT")+'</i></span>') : ' '
            $(".tablelist" + i + " thead tr:first .underlying_ticker").text(
              ric_data["underlying_ticker"]
            );
            $(".tablelist" + i + " thead tr:first .dsply_nmll").text(
              ric_data["dsply_nmll"]
            );
            var html = "",
              thHtml = "";
            if (_this.$js.isRHB(ric_data.issuer_name)) {
              let annotation = "annotation";
              var colorclass = "cc-wh";
              var pastvalue = 0;
              thHtml +=
                '<th scope="col" class="details-banpubg text-white border-right-0" width="25%">' +
                _this.$t("m.bid") +
                "</th>";
              thHtml +=
                '<th scope="col" class="standard-bgcolor text-white border-right-0" width="25%">' +
                _this.$t("m.bid") +
                "</th>";
              thHtml +=
                '<th scope="col" class="standard-bgcolor text-white border-right-0 dataTxt" width="25%">' +
                _this.$t("m.ask") +
                "</th>";
              thHtml +=
                '<th scope="col" class="details-banpubg text-white border-right-0 dataTxt" width="25%">' +
                _this.$t("m.ask") +
                "</th>";

              $.each(live_matrix, function (index, datas) {
                // if (index === 0) {
                //   thHtml += '<th scope="col" class="standard-bgcolor text-white border-right-0">' + _this.$t("m.bid") + '</th>'
                //   for (let i = 1; i <= 5; i++) {
                //     thHtml +=
                //       '<th scope="col" class="standard-bgcolor text-white border-right-0 dataTxt" >' +
                //       new Date(datas["date" + i]).toUTCString().split(" ")[1] +
                //       "-" +
                //       new Date(datas["date" + i]).toUTCString().split(" ")[2] +
                //       "</th>";
                //   }
                // }

                annotation =
                  ric_data.underlying_bid === Number(datas.underlying_bid)
                    ? "annotation"
                    : " ";
                if (index == 0) {
                  // html +=
                  //   '<tr class="' +
                  //   colorclass +
                  //   '"><td class="' +
                  //   annotation +
                  //   '">' +
                  //   _this.$js.formatNumber(datas.underlying_bid, 0, 1) +
                  //   "</td><td>" +
                  //   _this.$js.formatNumber(datas.bid1, 0, 1) +
                  //   "</td><td>" +
                  //   _this.$js.formatNumber(datas.bid2, 0, 1) +
                  //   "</td><td>" +
                  //   _this.$js.formatNumber(datas.bid3, 0, 1) +
                  //   "</td><td>" +
                  //   _this.$js.formatNumber(datas.bid4, 0, 1) +
                  //   "</td><td>" +
                  //   _this.$js.formatNumber(datas.bid5, 0, 1) +
                  //   "</td></tr>";
                  html +=
                    '<tr class="' + colorclass + ' ' + annotation + '"><td>' +
                    _this.$js.formatNumber(datas.underlying_bid, 0, 1) +
                    "</td><td>" +
                    datas.bid1 +
                    "</td><td>" +
                    datas.ask1 +
                    "</td><td>" +
                    _this.$js.formatNumber(datas.underlying_ask, 0, 1) +
                    "</td></tr>";
                  pastvalue = datas.underlying_bid;
                  colorclass = "cc-wh";
                } else if (datas.bid == pastvalue) {
                  // html +=
                  //   '<tr class="' +
                  //   colorclass +
                  //   '"><td class="' +
                  //   annotation +
                  //   '">' +
                  //   _this.$js.formatNumber(datas.underlying_bid, 0, 1) +
                  //   "</td><td>" +
                  //   _this.$js.formatNumber(datas.bid1, 0, 1) +
                  //   "</td><td>" +
                  //   _this.$js.formatNumber(datas.bid2, 0, 1) +
                  //   "</td><td>" +
                  //   _this.$js.formatNumber(datas.bid3, 0, 1) +
                  //   "</td><td>" +
                  //   _this.$js.formatNumber(datas.bid4, 0, 1) +
                  //   "</td><td>" +
                  //   _this.$js.formatNumber(datas.bid5, 0, 1) +
                  //   "</td></tr>";
                  html +=
                    '<tr class="' + colorclass + ' ' + annotation + '"><td>' +
                    _this.$js.formatNumber(datas.underlying_bid, 0, 1) +
                    "</td><td>" +
                    datas.bid1 +
                    "</td><td>" +
                    datas.ask1 +
                    "</td><td>" +
                    _this.$js.formatNumber(datas.underlying_ask, 0, 1) +
                    "</td></tr>";
                } else if (datas.bid != pastvalue) {
                  if (colorclass == "cc-wh") {
                    colorclass = "cc-bg";
                  } else {
                    colorclass = "cc-wh";
                  }
                  // html +=
                  //   '<tr class="' +
                  //   colorclass +
                  //   '"><td class="' +
                  //   annotation +
                  //   '">' +
                  //   _this.$js.formatNumber(datas.underlying_bid, 0, 1) +
                  //   "</td><td>" +
                  //   _this.$js.formatNumber(datas.bid1, 0, 1) +
                  //   "</td><td>" +
                  //   _this.$js.formatNumber(datas.bid2, 0, 1) +
                  //   "</td><td>" +
                  //   _this.$js.formatNumber(datas.bid3, 0, 1) +
                  //   "</td><td>" +
                  //   _this.$js.formatNumber(datas.bid4, 0, 1) +
                  //   "</td><td>" +
                  //   _this.$js.formatNumber(datas.bid5, 0, 1) +
                  //   "</td></tr>";
                  html +=
                    '<tr class="' + colorclass + ' ' + annotation + '"><td>' +
                    _this.$js.formatNumber(datas.underlying_bid, 0, 1) +
                    "</td><td>" +
                    datas.bid1 +
                    "</td><td>" +
                    datas.ask1 +
                    "</td><td>" +
                    _this.$js.formatNumber(datas.underlying_ask, 0, 1) +
                    "</td></tr>";
                  pastvalue = datas.underlying_bid;
                }
              });
              if (live_matrix.length > 0) {
                html +=
                  "<tr><td colspan='6'>Publish Time: " +
                  _this.$js.formatDate15(live_matrix[0].publish_time) +
                  " GMT +7" +
                  " </td></tr>";
              }
            } else {
              html =
                "<tr><td colspan='4'>Warrant matrix is only available for warrants issued by RHB</td></tr>";
            }
            $(".tablelist" + i + " thead")
              .find("tr")
              .eq(1)
              .html(thHtml);
            $(".tablelist" + i + " tbody").html(html);
          }
        },
        error: function (error) {
          console.log(error);
        }
      });

      if (isInit !== true) {
        var arr = [];
        if (localStorage.getItem("priceMatrix") !== null) {
          arr = localStorage.getItem("priceMatrix").split(",");
        }
        arr[i - 1] = ric;
        // localStorage.clear()
        localStorage.setItem("priceMatrix", arr);
      }
    },

    printTable: function () {
      /*下载table*/
      let _this = this;
      $("#tableToExcel").click(function () {
        if (localStorage.getItem("priceMatrix") !== null) {
          var arr = localStorage.getItem("priceMatrix").split(",");
          var divBoxArr = [];
          arr.forEach(function (it, index) {
            var i = index + 1;
            if (it !== "") {
              /*判断哪一个panel有存储*/
              divBoxArr.push({
                div: "#datalist" + i,
                name: it.split(".")[0]
              });
            }
          });
          _this.$js.MatrixExport_table(divBoxArr);
        }
      });
    },

    correlationPrintTbale: function () {
      /*打印table*/
      let _this = this;
      $("#tableToWord").click(function () {
        if (localStorage.getItem("priceMatrix") !== null) {
          var arr = localStorage.getItem("priceMatrix").split(",");
          var printData;
          arr.forEach(function (it, index) {
            var i = index + 1;
            if (it !== "") {
              /*判断哪一个panel有存储*/
              printData += $("#datalist" + i).html();
              // _this.$js.pringtingTable("#datalist" + i);
            }
          });
          _this.$js.MatrixPringtingTable(printData);
        }
      });
    },
    getRicToDetails: function (i) {
      /*跳转至details*/
      var ric = "";
      if (localStorage.getItem("priceMatrix") !== null) {
        var arr = localStorage.getItem("priceMatrix").split(",");
        arr.forEach(function (it, index) {
          if (i == index) {
            ric = it;
          }
        });
      }
      localStorage.setItem("detailsRic", ric);
      location.href = "warrantdetails?dsplay_nmll=" + this.dsply_nmll[i];
    }
  }
};
